import React from "react";
import { useSelector } from "react-redux";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import DOMPurify from "dompurify";
import { Icon } from "@iconify/react";

import stylesheet from "./ProductDetail.module.scss";
import { handleImageLoadError } from "../../utils/handleImageLink";
import {
	FiraProductVariation,
	ProductInfo,
	RootState,
	VODType,
} from "../../utils/types";
import { DataContext } from "../../context/DataContext";
import currencyFormat from "../../utils/currencyFormat";
import { getFontColor } from "../../utils/getFontColor";
import { ProductClickOrigin } from "../../store/PopUpActions";
import {
	doClickToProduct,
	ProductClickType,
	regularClickVOD,
} from "../../utils/actions";
import { useTranslation } from "react-i18next";
import { getUser } from "../../utils/chatUserActions";
import { CartProduct, useCart } from "../../context/CartContext";
import { usePlayerState } from "../../context/PlayerStateProvider";

const {
	ComponentWrapper,
	HeaderWrapper,
	CloseButton,
	Preview,
	Details,
	Labels,
	Name,
	Description,
	DescriptionAlt,
	PriceWrapper,
	Dash,
	SliderWrapper,
	ContentWrapper,
	OptionWrapper,
	FooterWrapper,
	Title,
	OptionList,
	BackButton,
	ControlWrapper,
	IconWrapper,
	GalleryHeader,
	GalleryWrapper,
	BulletList,
	Bullet,
} = stylesheet;

const createMarkup = (htmlString: string) => {
	return {
		__html: DOMPurify.sanitize(htmlString, { ADD_ATTR: ["target"] }),
	};
};

const urlParams = new URLSearchParams(window.location.search);
const fira_src = urlParams.get("firasrc") || "";
const shopping_cart = urlParams.get("shoppingCart") || "";

interface Props {
	onClose(): void;
}
const ProductDetail: React.FC<Props> = ({ onClose }) => {
	const popup = useSelector((state: RootState) => state.popup);
	const { broadcast, queues, vod }: any = React.useContext(DataContext);
	const { t } = useTranslation();
	const { currency, firaWebConfiguration } = broadcast;
	const mainColor = firaWebConfiguration?.mainColor || "#010202";
	const product: ProductInfo = popup.product.product;
	const [showImages, setShowImages] = React.useState(false);
	const [addedToCart, setAddedToCart] = React.useState(false);
	const [selectedOptions, setSelectedOptions] = React.useState<{
		[key: string]: string;
	}>({});
	const [qty, setQty] = React.useState(1);
	const [selectedVariation, setSelectedVariation] =
		React.useState<FiraProductVariation>();
	const [isAddToCartEnabled, setIsAddToCartEnabled] = React.useState(false);

	const options = product.firaProductOptionDtos || [];
	const labels = product.labels || [];
	const variations = product.firaVariationsDtos || [];

	const vodData: VODType = vod?.vod;
	const hasCart = typeof shopping_cart === "string" && shopping_cart === "true";
	const { addProductToCart } = useCart();
	const { additionalFields, urlContruct } = usePlayerState();

	const toggleGallery = () => {
		setShowImages(!showImages);
	};

	const handleAddToCart = () => {
		const currentUser = getUser(fira_src);
		if (currentUser) {
			switch (popup.from) {
				case ProductClickOrigin.regularProduct:
					doClickToProduct(
						currentUser.id,
						popup.product,
						fira_src,
						queues.connectionId,
						broadcast.startDate,
						ProductClickType.gallery,
						broadcast.parentId ? broadcast.parentId : undefined
					);
					break;
				case ProductClickOrigin.popUpProduct:
					doClickToProduct(
						currentUser.id,
						popup.product,
						fira_src,
						queues.connectionId,
						broadcast.startDate,
						ProductClickType.popup,
						broadcast.parentId ? broadcast.parentId : undefined
					);
					break;
				default:
					break;
			}
		}
		setAddedToCart(true);
		const { name, price1, price2, images, id } = product;
		const productToCart: CartProduct = {
			name,
			image:
				selectedVariation && selectedVariation.image
					? selectedVariation.image
					: images.length > 0
					? images[0]
					: "",
			price1:
				selectedVariation && selectedVariation.price1
					? selectedVariation.price1
					: price1,
			price2:
				selectedVariation && selectedVariation.price2
					? selectedVariation.price2
					: price2,
			sku: selectedVariation ? selectedVariation.sku : id,
			quantity: qty,
			variationName: selectedVariation?.name,
		};
		addProductToCart(productToCart);
		setTimeout(() => setAddedToCart(false), 1500);
	};

	const handleUrlRedirect = () => {
		if (product.isAdvancedUrl && additionalFields) {
			const finalUrl = urlContruct(product.directUrl, additionalFields);
			window.open(finalUrl, "_blank", "noreferrer");
		} else {
			window.open(product?.directUrl, "_blank", "noreferrer");
		}

		const currentUser = getUser(fira_src);
		//Send Click on Product
		if (broadcast.status === "STARTED" && currentUser) {
			switch (popup.from) {
				case ProductClickOrigin.regularProduct:
					doClickToProduct(
						currentUser.id,
						popup.product,
						fira_src,
						queues.connectionId,
						broadcast.startDate,
						ProductClickType.gallery,
						broadcast.parentId ? broadcast.parentId : undefined
					);
					break;
				case ProductClickOrigin.popUpProduct:
					doClickToProduct(
						currentUser.id,
						popup.product,
						fira_src,
						queues.connectionId,
						broadcast.startDate,
						ProductClickType.popup,
						broadcast.parentId ? broadcast.parentId : undefined
					);
					break;
				default:
					break;
			}
		} else {
			const currentTime =
				(document.querySelector("#fira_player") as HTMLVideoElement)
					.currentTime || 0;
			regularClickVOD(
				popup.product,
				vod.connectionId,
				vodData.id,
				vodData.storeId,
				broadcast.currency,
				currentTime
			);
		}
	};

	const findMatchingVariation = (currentSelections: {
		[key: string]: string;
	}): FiraProductVariation | undefined => {
		const allCategoriesSelected = options.every(
			option => option.name in currentSelections
		);

		if (!allCategoriesSelected) {
			return undefined;
		}

		const variationName = options
			.map(option => currentSelections[option.name])
			.join("/");

		const matchingVariation = variations.find(
			variation => variation.name === variationName
		);

		return matchingVariation ? matchingVariation : undefined;
	};

	const handleOptionSelection = (option: { title: string; option: string }) => {
		const newSelectedOptions = {
			...selectedOptions,
			[option.title]: option.option,
		};

		const filteredSelectedOptions = Object.keys(newSelectedOptions).reduce(
			(acc: { [key: string]: string }, category) => {
				if (
					category === option.title ||
					isSelectionStillValid(
						category,
						newSelectedOptions[category],
						newSelectedOptions
					)
				) {
					acc[category] = newSelectedOptions[category];
				}
				return acc;
			},
			{}
		);

		setSelectedOptions(filteredSelectedOptions);

		const matchingVariation = findMatchingVariation(newSelectedOptions);

		if (matchingVariation) {
			console.log("MATCH", matchingVariation);
			setSelectedVariation(matchingVariation);
		} else {
			setSelectedVariation(undefined);
		}
	};

	const isSelectionStillValid = (
		optionCategory: string,
		optionItem: string,
		currentSelections: { [key: string]: string }
	) => {
		const variationName = Object.entries(currentSelections)
			.filter(([category]) => category !== optionCategory || optionItem)
			.map(([, item]) => item)
			.join("/");

		return variations.some(variation => variation.name.includes(variationName));
	};

	const isVariationAvailable = (optionCategory: string, optionItem: string) => {
		const potentialSelections = {
			...selectedOptions,
			[optionCategory]: optionItem,
		};

		let possibleVariations = variations;

		Object.keys(potentialSelections).forEach(category => {
			possibleVariations = possibleVariations.filter(variation => {
				const parts = variation.name.split("/");
				const partForCategory = parts.find(part =>
					part.startsWith(potentialSelections[category])
				);
				return partForCategory !== undefined;
			});
		});

		return possibleVariations.length > 0;
	};

	const buttonText =
		broadcast?.firaWebConfiguration?.buyButtonText ||
		t("components.product_popup.buy");
	const getButtonText = () => {
		if (
			buttonText === null ||
			buttonText === undefined ||
			buttonText.trim() === "" ||
			buttonText === "Comprar"
		) {
			return t("components.product_popup.buy");
		} else {
			return buttonText;
		}
	};

	const renderProductOption = (
		key: number,
		title: string,
		options: string[]
	) => {
		return (
			<div key={key} className={OptionWrapper}>
				<span className={Title}>
					{title}: {selectedOptions[title]}{" "}
				</span>
				<div className={OptionList}>
					{options.map((o, index) => {
						const isSelected = selectedOptions[title] === o;
						const isAvailable = isVariationAvailable(title, o);
						return (
							<button
								key={index}
								style={{
									backgroundColor: isSelected
										? mainColor
										: isAvailable
										? "#fff"
										: "#d5dde0",
									color: isSelected
										? getFontColor(mainColor)
										: isAvailable
										? "#000"
										: "#fff",
								}}
								onClick={() => handleOptionSelection({ title, option: o })}
							>
								{o}
							</button>
						);
					})}
				</div>
			</div>
		);
	};

	const renderFooter = (hasCart = true) => {
		return (
			<div className={FooterWrapper}>
				{hasCart ? (
					<>
						<QuantityControl
							value={qty}
							onAdd={() => setQty(qty + 1)}
							onSubtract={() => setQty(prevQty => Math.max(prevQty - 1, 1))}
						/>
						<button
							onClick={handleAddToCart}
							style={{
								backgroundColor: addedToCart ? "#fff" : mainColor,
								color: addedToCart ? mainColor : getFontColor(mainColor),
							}}
							disabled={!isAddToCartEnabled}
						>
							{addedToCart
								? t("components.product_popup.added_cart")
								: t("components.product_popup.add_cart")}
						</button>
					</>
				) : (
					<>
						<button
							onClick={handleUrlRedirect}
							style={{
								backgroundColor: mainColor,
								color: getFontColor(mainColor),
							}}
						>
							{getButtonText()}
						</button>
					</>
				)}
			</div>
		);
	};

	const renderPrice = (price?: number | string, isDiscount = false) => {
		return (
			<span className={isDiscount ? Dash : ""}>
				{typeof price === "string"
					? currencyFormat(parseFloat(price), currency)
					: currencyFormat(price || 0, currency)}
			</span>
		);
	};

	const renderProductDetails = () => {
		return (
			<>
				<div className={ContentWrapper}>
					<div className={HeaderWrapper}>
						<div className={Preview}>
							<MiniCarrouselGallery
								mainColor={mainColor}
								images={product.images}
								onGalleryClick={toggleGallery}
							/>
						</div>
						<div className={Details}>
							<div className={Labels}>
								{labels.map((l, index) => (
									<div
										key={index}
										style={{
											backgroundColor: `${
												!l.labelColor ? "red" : `#${l.labelColor}`
											}`,
											color: `${!l.fontColor ? "#fff" : `#${l.fontColor}`}`,
										}}
									>
										{l.name}
									</div>
								))}
							</div>
							<span className={Name}>{product.name}</span>
							<div className={PriceWrapper}>
								{selectedVariation &&
								selectedVariation.price2 &&
								parseFloat(selectedVariation.price2) > 0 ? (
									renderPrice(selectedVariation.price2)
								) : !selectedVariation &&
								  product.price2 &&
								  parseFloat(product.price2) > 0 ? (
									renderPrice(product.price2)
								) : (
									<></>
								)}
								{selectedVariation
									? renderPrice(
											selectedVariation.price1,
											parseFloat(selectedVariation.price2 || "0") > 0
									  )
									: renderPrice(product.price1, parseFloat(product.price2) > 0)}
							</div>
							<div
								className={Description}
								dangerouslySetInnerHTML={createMarkup(
									product.description || ""
								)}
							></div>
						</div>
						<button onClick={onClose} className={CloseButton}>
							<Icon color="#D9D9D9" icon="akar-icons:cross" />
						</button>
					</div>
					<div
						className={DescriptionAlt}
						dangerouslySetInnerHTML={createMarkup(product.description || "")}
					></div>
					{hasCart &&
						options &&
						options.length > 0 &&
						options.map((o, index) =>
							renderProductOption(index, o.name, o.optionItems)
						)}
				</div>
				{renderFooter(hasCart)}
			</>
		);
	};

	const renderProductsImages = () => {
		return (
			<>
				<div className={GalleryHeader}>
					<button onClick={toggleGallery} className={BackButton}>
						<Icon color="#D9D9D9" fontSize={20} icon="ph:arrow-left" />
					</button>
					<button className={CloseButton}>
						<Icon
							onClick={onClose}
							color="#D9D9D9"
							fontSize={20}
							icon="akar-icons:cross"
						/>
					</button>
				</div>
				<div className={stylesheet.BigGalleryWrapper}>
					<MiniCarrouselGallery mainColor={mainColor} images={product.images} />
				</div>
			</>
		);
	};

	React.useEffect(() => {
		setSelectedVariation(undefined);
		setSelectedOptions({});
	}, [product]);

	React.useEffect(() => {
		// Check if a matching variation exists or if the product has no variations
		const matchingVariation = findMatchingVariation(selectedOptions);
		if (matchingVariation !== undefined || variations.length === 0) {
			setIsAddToCartEnabled(true);
		} else {
			setIsAddToCartEnabled(false);
		}
	}, [selectedOptions, variations]);

	return (
		<div className={ComponentWrapper}>
			{showImages ? renderProductsImages() : renderProductDetails()}
		</div>
	);
};

interface MiniCarrouselGalleryProps {
	images: string[];
	mainColor: string;
	onGalleryClick?(): void;
}
const MiniCarrouselGallery: React.FC<MiniCarrouselGalleryProps> = ({
	images,
	mainColor,
	onGalleryClick,
}) => {
	const galleryRef = React.useRef<ImageGallery>(null);
	const [imgIndex, setImageIndex] = React.useState(0);
	const items: ReactImageGalleryItem[] = images.map(i => {
		return { original: i, originalHeight: 300 };
	});

	const handleBack = () => {
		const currentIndex = galleryRef.current?.getCurrentIndex();
		if (typeof currentIndex === "number") {
			if (currentIndex <= 0) {
				galleryRef.current?.slideToIndex(items.length - 1);
			} else {
				galleryRef.current?.slideToIndex(currentIndex - 1);
			}
		}
	};

	const handleNext = () => {
		const currentIndex = galleryRef.current?.getCurrentIndex();
		if (typeof currentIndex === "number") {
			const lastIndex = items.length - 1;
			if (currentIndex >= lastIndex) {
				galleryRef.current?.slideToIndex(0);
			} else {
				galleryRef.current?.slideToIndex(currentIndex + 1);
			}
		}
	};

	const updateBullet = (currentIndex: number) => {
		setImageIndex(currentIndex);
	};

	return (
		<div className={SliderWrapper}>
			<button className={stylesheet.Leading} onClick={handleBack}>
				<Icon fontSize={32} color={mainColor} icon="tabler:chevron-left" />
			</button>
			<div className={GalleryWrapper} onClick={onGalleryClick}>
				<ImageGallery
					ref={galleryRef}
					items={items}
					autoPlay={false}
					infinite
					showFullscreenButton={false}
					showPlayButton={false}
					showThumbnails={false}
					showNav={false}
					showBullets={false}
					onImageError={handleImageLoadError}
					onSlide={updateBullet}
				/>
				<div className={BulletList}>
					{items.map((i, index) => (
						<div
							key={index}
							style={{
								backgroundColor: index === imgIndex ? mainColor : "#D9D9D9",
							}}
							className={Bullet}
						></div>
					))}
				</div>
			</div>
			<button className={stylesheet.Trailing} onClick={handleNext}>
				<Icon fontSize={32} color={mainColor} icon="tabler:chevron-right" />
			</button>
		</div>
	);
};

interface QuantityControlProps {
	value: number;
	onSubtract(): void;
	onAdd(): void;
}
const QuantityControl: React.FC<QuantityControlProps> = ({
	value,
	onSubtract,
	onAdd,
}) => {
	return (
		<div className={ControlWrapper}>
			<div className={IconWrapper} onClick={onSubtract}>
				<Icon icon="ic:baseline-minus" fontSize={16}></Icon>
			</div>
			<div>{value}</div>
			<div className={IconWrapper} onClick={onAdd}>
				<Icon icon="ic:baseline-plus" fontSize={16}></Icon>
			</div>
		</div>
	);
};

export default ProductDetail;
